import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
    name: 'trainingPoint',
    initialState: {
        trainingPoint: {
            descripcion: '',
            direccion: '',
            telefono: '',
            id: 0,
            entrenador: {},
            mensualidades: [],
        },
        trainingPoints:[],
        totalByTrainingPoint: {
            totalByUsers: [],
            total: 0,
            totalAsistencias: 0
        }
    },
    reducers: {
        setTrainingPoint: (state, action) => {
            state.trainingPoint = action.payload;
        },
        setTrainingPoints: (state, action) => {
            state.trainingPoints = action.payload;
        },
        setTotalByTrainingPoint: (state, action) => {
            state.totalByTrainingPoint = action.payload;
        }
    }
});

export const { setTrainingPoint } = customersSlice.actions;
export const { setTotalByTrainingPoint } = customersSlice.actions;
export const { setTrainingPoints } = customersSlice.actions;

export default customersSlice.reducer;
