

import { getConfigFile } from '../app-config';
import { getEnvironment } from '../environment';
import BaseAPI from './api';

const endpoint = 'periodos';
const API = BaseAPI?.axiosInstance;
const env = getConfigFile().global;

const getTokenHeaders = () => {
    let unparsedToken = localStorage.getItem('user-token');
    if (!unparsedToken) {
        unparsedToken = ' ';
    }
    return {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(unparsedToken)}`

    }
}


export const periodoGetDatos = async (search_term: string, pagina: string) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('user-token')
    };
    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}/grouped_by_month`, {
        headers: getTokenHeaders(),
        params: {
            search_term,
            pagina
        }
    }).then(
        (res) => res.data
    );
    return response.data;
};


export const periodoMarkAsPaid = async (periodoId: string, sateliteId: string, valor: string) => {
    const response = await API.put(`${getEnvironment().baseUrl}${endpoint}/${periodoId}/mark_paids/${sateliteId}`, {
        valor

    }, {
        headers: getTokenHeaders()
    }).then((res) => res);
    return response;
};

