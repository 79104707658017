import React, { useEffect, useState } from 'react';
import { satelitesGetDatos } from "../../http/satelite-services";
import { useNavigate } from 'react-router';
import { PAGINA_INICIAL } from "../RegisterUserAccess/RegisterUserAccess";
import { ITrainingPoint } from './TrainingPoints.interface';
import { getEnvironment } from '../../environment';


function TrainingPointsList() {
    const navigate = useNavigate();
    const [satelites, setSatelites] = useState<Array<ITrainingPoint>>([]);

    const fetchSatelites = async () => {
        const satelitesData = await satelitesGetDatos('', PAGINA_INICIAL);
        setSatelites(satelitesData.data);
    }

    useEffect(() => {
        fetchSatelites();
    }, [1]);

    const getImageURL = (id: number | undefined) => {
        if (id) {
            return `${getEnvironment().imageUrl}satelites/${id}.jpeg`;
        }

        return ``;

    }

    const goToCosts = (id: number | undefined) => {
        if (id) {
            navigate(`/cost-by-assistance/${id}`);
        }
        return;
    }

    return (
        <div className="flex flex-col w-full h-full py-8 items-center ">
            <div className="flex flex-col gap-4 items-center w-full">
                <h1 className=''>Puntos de entreno</h1>
                <div className="flex flex gap-4 w-full">

                    {satelites.map((satelite) =>
                    (<div className='flex border border-solid border-light-gray rounded cursor-pointer rounded-md ' key={Math.random()} onClick={() => goToCosts(satelite.id)}>
                        <div className='flex flex-col'>
                            <img className="max-h-15" src={getImageURL(satelite.id)} alt="userIcon" />
                            <div className='flex flex-col justify-center items-center p-4'>
                                <h2 className=''>
                                    {satelite.descripcion}
                                </h2>
                                <p>{satelite.tipo}</p>
                                <p>{satelite.telefono}</p>
                                <p>{satelite.direccion}</p>
                            </div>
                        </div>
                    </div>)

                    )
                    }
                </div>

                <div className='flex'>

                </div>
            </div>
        </div>
    )
}

export default TrainingPointsList;