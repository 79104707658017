import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
    name: 'activities',
    initialState: {
        activities: [],
        activity: {
            id: 0,
            descripcion: '',
            descripcionLarga: '',
            tipo_entreno: '',
            calificacion_promedio: 0,
            numero_calificaciones: 0,
            is_virtual: false,
            descripcion_larga:''
        }

    },
    reducers: {
        setActivities: (state, action) => {
            state.activities = action.payload;
        },
        setActivity: (state, action) => {
            state.activity = action.payload;
        }
    }
});

export const { setActivities } = customersSlice.actions;
export const { setActivity } = customersSlice.actions;

export default customersSlice.reducer;
