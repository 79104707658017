import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    requestBody: {} as any,
    tokenId: '',
    userRol: null,
    userProfile: {
      id: 0,
      username: "",
      username_canonical: "",
      email: "",
      email_canonical: "",
      enabled: true,
      password: "",
      last_login: "",
      groups: [],
      nombre: "",
      telefono: "",
      direccion: "",
      player_id: "",
      genero: "",
      objetivo: "",
      fecha_nacimiento: "",
      como_llego: "",
      embajador: false,
      password_assigned: true,
      cedula: 1143825718,
      rolePermissions: null,
      userFeatures: null,
      expirationTime: - 1,
      timeRemaining: -1,
      timerStarted: false,
      isLoggedIn: false,
      roles: [''],
      empresa: {
        enabled: false,
        id: 0,
        nombre: "",
        razon_social: ""
      }
    },
    rolePermissions: sessionStorage.getItem('rolePermissions') || null,
    userFeatures: sessionStorage.getItem('userFeatures') || null,
    expirationTime:
      sessionStorage.getItem('expirationTime') !== undefined &&
        sessionStorage.getItem('expirationTime') !== null
        ? parseInt(sessionStorage.getItem('expirationTime') || '0')
        : -1,
    timeRemaining: -1,
    timerStarted: false,
    isLoggedIn:
      localStorage.getItem('user-token') !== undefined &&
        localStorage.getItem('user-token') !== null &&
        localStorage.getItem('user-token') !== 'null' &&
        localStorage.getItem('user-token') === 'true'
        ? true
        : false,

    userToRequestAccess: {
      nombre: '',
      cedula: '',
      telefono: '',
      email: '',
      password: '',
    }
  },

  reducers: {
    loginStep2Action: (state, action) => {
      state.requestBody = action.payload;
    },
    setOTP: (state, action) => {
      state.requestBody.callbacks[0].input[0].value = action.payload;
    },
    setTokenId: (state, action) => {
      state.tokenId = action.payload.token;
      state.isLoggedIn = true;
      sessionStorage.setItem('loggedIn', 'true');
    },
    setUserRol: (state, action) => {
      state.userRol = action.payload;
    },
    setRolePermissions: (state, action) => {
      state.rolePermissions = action.payload;
      sessionStorage.setItem('rolePermissions', action.payload);
    },
    setUserFeatures: (state, action) => {
      state.userFeatures = action.payload;
      sessionStorage.setItem('userFeatures', action.payload);
    },
    getState: (state) => {
      return state;
    },
    logIn: (state) => {
      state.isLoggedIn = true;
      sessionStorage.setItem('loggedIn', 'true');
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      sessionStorage.clear();
      localStorage.clear();
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setUserToRequestAccess: (state, action) => {
      state.userToRequestAccess = action.payload;
    },

  }
});

export const {
  loginStep2Action,
  setOTP,
  setTokenId,
  setUserRol,
  setRolePermissions,
  setUserFeatures,
  setUserProfile,
  getState,
  logIn,
  logOut,
  setUserToRequestAccess
} = userSlice.actions;

export default userSlice.reducer;
