import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userReducer';
import registerUserAccessReducer from './registerUserAccessReducer';
import costByAssistanceReducer from './costByAssistanceReducer';
import trainingPointReducer from './trainingPointReducer';
import classesReducer from './classesReducer';
import activitiesReducer from './activitiesReducer';
import companiesReducer from './companiesReducer';

export const store = configureStore({
  reducer: {
    activities: activitiesReducer,
    user: userReducer,
    registerUserAccess: registerUserAccessReducer,
    costByAssistance: costByAssistanceReducer,
    trainingPoint: trainingPointReducer,
    classes: classesReducer,
    companies: companiesReducer
  }
});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
