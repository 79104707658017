import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import GCPrimaryButton from '../../primitives/buttons/GCPrimaryButton';

import GCInput from '../../primitives/inputs/GCInput.primitive';
import { useParams } from 'react-router-dom';
import { setPrecioPuntoEntreno } from '../../app/costByAssistanceReducer';
import { postCostByAssistance } from '../../http/cost-by-assitance-services';
import { getByPuntoEntrenoId } from '../../http/precio-punto-entreno-services';


function CostByAssistance(props: any) {
    let { puntoEntrenoId } = useParams();
    const dispatch = useAppDispatch();
    const precioPuntoEntreno = useAppSelector((state) => state?.costByAssistance?.precioPuntoEntreno)

    const handleSubmit = async () => {
        //setCedula(event?.target.value);3
        let tmpVal = Object.assign({}, precioPuntoEntreno);
        if (puntoEntrenoId) {
            tmpVal['satelite_id'] = puntoEntrenoId;
        }
        const response = await postCostByAssistance(tmpVal)
    };


    const setPrecioPuntoEntrenoFn = async (puntoEntrenoId: string) => {
        dispatch(setPrecioPuntoEntreno({
            costo_max_cuatro_usos: '',
            costo_max_ocho_usos: '',
            costo_max_doce_usos: '',
            costo_max_quince_usos: '',
            costo_mas_quince_usos: '',
            costo_clase: '',
            costo_mensualidad: '',
            satelite_id: '',
        }));
        try {
            let tmpPreciosPuntoEntreno = await getByPuntoEntrenoId(puntoEntrenoId);
            dispatch(setPrecioPuntoEntreno(tmpPreciosPuntoEntreno));
        } catch (error) {

        }
    }

    useEffect(() => {
        if (puntoEntrenoId) {
            setPrecioPuntoEntrenoFn(puntoEntrenoId);
        }
    }, [1]);

    const setCost = async (attribute: 'costo_max_cuatro_usos' | 'costo_max_ocho_usos' | 'costo_max_doce_usos' | 'costo_max_quince_usos' | 'costo_mas_quince_usos' | 'costo_clase' | 'costo_mensualidad' | 'satelite_id', newValue: string) => {
        let tmpVal = Object.assign({}, precioPuntoEntreno);
        tmpVal[attribute] = newValue;
        dispatch(setPrecioPuntoEntreno(tmpVal));
    };

    return (
        <div className='flex w-full justify-center items-center py-8'>
            <div className='flex flex-col gap-4 w-1/3 '>
                <div className="flex flex-col gap-2 p-4  justify-center w-full ">
                    <h1 className='font-sans text-xl text-bold'>Costo por persona y asistencias</h1>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Hasta 4 asistencias</label>
                        <GCInput id="cost-max-four-uses" type="text" name="cost-max-four-uses" previousValue={precioPuntoEntreno.costo_max_cuatro_usos} placeholder="" onChange={(newValue) => {
                            setCost('costo_max_cuatro_usos', newValue);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Hasta 8 asistencias</label>
                        <GCInput id="cost-max-eight-uses-id" type="text" name="cost-max-four-uses" previousValue={precioPuntoEntreno.costo_max_ocho_usos} placeholder="" onChange={(newValue) => {
                            setCost('costo_max_ocho_usos', newValue);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Hasta 12 asistencias</label>
                        <GCInput id="cost-twelve-four-uses-id" type="text" name="cost-max-four-uses" previousValue={precioPuntoEntreno.costo_max_doce_usos} placeholder="" onChange={(newValue) => {
                            setCost('costo_max_doce_usos', newValue);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Hasta 15 asistencias</label>
                        <GCInput id="cost-max-fifteen-uses-id" type="text" name="cost-max-fifteen-uses" previousValue={precioPuntoEntreno.costo_max_quince_usos} placeholder="" onChange={(newValue) => {
                            setCost('costo_max_quince_usos', newValue);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Mas de 15 asistencias</label>
                        <GCInput id="cost-more-fifteen-uses-id" type="text" name="cost-max-fifteen-uses" previousValue={precioPuntoEntreno.costo_mas_quince_usos} placeholder="" onChange={(newValue) => {
                            setCost('costo_mas_quince_usos', newValue);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Costo por clase</label>
                        <GCInput id="cost-by-class-id" type="text" name="cost-by-class" previousValue={precioPuntoEntreno.costo_clase} placeholder="" onChange={(newValue) => {
                            setCost('costo_clase', newValue);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Costo Mensualidad</label>
                        <GCInput id="monthly-fee" type="text" name="monthly-fee" previousValue={precioPuntoEntreno.costo_mensualidad} placeholder="" onChange={(newValue) => {
                            setCost('costo_mensualidad', newValue);
                        }} />
                    </div>
                    <div>
                        <GCPrimaryButton disabled={false} text={'Guardar'} id={'next'} onClick={handleSubmit} />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default CostByAssistance;