import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
    name: 'companies',
    initialState: {
        users: [] as any[],
        plan: {
            cliente: {},
            enabled: false,
            fecha_inicio: '',
            fecha_fin: '',
            id: 0,
            mensualidad: {
                descripcion: '',
                enabled: false,
                id: 0,
                nro_licencias: 0,
                precio: 0,
                tipo: '',
                titulo: '',
            }
        }

    },
    reducers: {
        setCompanyUsers: (state, action) => {
            state.users = action.payload;
        },
        setCompanyPlan: (state, action) => {
            state.plan = action.payload;
        },
    }
});

export const { setCompanyUsers } = customersSlice.actions;
export const { setCompanyPlan } = customersSlice.actions;

export default customersSlice.reducer;
