import React, { useEffect, useState } from 'react';

import './GCInput.css';
import RedWarning from '../../assets/red-warning.svg';

type BiInputProps = {
  id: string;
  previousValue: string;
  placeholder: string;
  name: string;
  maxWidth?: string;
  height?: string;
  hasError?: boolean;
  errorDescription?: string;
  hintDescription?: string;
  touched?: boolean;
  type?: string;
  onChange: (input: string) => void;
  onKeyPressFn?: (event: any) => void;
};

const GCInput = ({
  previousValue,
  placeholder,
  name,
  maxWidth,
  onChange,
  onKeyPressFn,
  hasError,
  errorDescription,
  hintDescription,
  height,
  type
}: BiInputProps) => {
  const [inputValue, setInputValue] = useState(previousValue);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setInputValue(previousValue)
   
   }, [previousValue]);
  return (
    <>
      <input
        name={name}
        className={
          'gc-input border border-md-gray solid rounded-md bg-white px-2 py-1 ' +
          (height ? ' ' + height + ' ' : 'h-8') +
          (hasError && touched ? ' border-danger' : 'border-[#8c8c8c]')
        }
        style={{
          maxWidth: maxWidth ? maxWidth : '100%'
        }}
        value={inputValue}
        placeholder={placeholder}
        onChange={(e) => {
          setTouched(true);
          setInputValue(e.target.value);
          onChange(e.target.value);
        }}
        onKeyDown={onKeyPressFn}
        type={type}
      />
      {hasError && touched ? (
        <div className="flex items-center gap-1 text-sm text-danger">
          {/*           
          <img src={RedWarning} alt="red-warning"></img>
          */}
{errorDescription}
        </div>
      ) : (
        ''
      )}
      {!touched && hintDescription ? (
        <span className="text-sm text-gray-700">{hintDescription}</span>
      ) : (
        ''
      )}
    </>
  );
};

export default GCInput;
