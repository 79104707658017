import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from './app/store';
import "tw-elements";
import "./tailwind/index.css";
import "./index.css";
import "./tailwind/index.css";
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="">
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>

    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
