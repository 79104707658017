import React from 'react';

import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from './components/Auth/Login';
import RegisterUserAccess from './components/RegisterUserAccess/RegisterUserAccess';
import { useAppSelector } from './app/hooks';
import CostByAssistance from './components/CostByAssistance/CostByAssistance';
import Header from './components/Header/Header';
import MainScreen from './components/MainScreen/MainScreen';
import MonthlyPay from './components/MonthlyPay/MonthlyPay';
import TrainingPointsList from './components/TrainingPoints/TrainingPointsList';
import ClassesList from './components/Classes/ClassesList';
import ClassesCreateEdit from './components/Classes/ClassesCreateEdit';
import MainScreenAdmin from './components/MainScreen/MainScreenAdmin';
import BuyUserLicences from './components/BuyUserLicences/BuyUserLicences';
import MainScreenAdminCompany from './components/MainScreen/MainScreenAdminCompany';
import UserLicencesList from './components/Users/UserLicencesList';
import UserRequestAccess from './components/Users/UserRequestAccess';
import ReportsWrapperScreen from './components/Reports/ReportsWrapperScreen';
import ReportAlliesWeeklyPay from './components/Reports/ReportAlliesWeeklyPay';
import ReservationsByUser from './components/Reports/ReservationsByUser';

function App() {
  const loggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const { pathname } = useLocation();

  return (
    <div className="h-screen w-screen flex flex-col" >

      <Header />
      <div className={"justify-center h-screen w-screen flex flex-col " + (pathname === "/login" ? ' justify-center' : '')} >

        <Routes>


          < Route path="login" element={<Login />} />
          < Route path="" element={<Login />} />

          {loggedIn && (
            <>
            </>)
          }
          <Route path="register-user-access" element={<RegisterUserAccess />} />
          <Route path="cost-by-assistance/:puntoEntrenoId" element={<CostByAssistance />} />
          <Route path="main-screen" element={<MainScreen />} />
          <Route path="main-screen-admin" element={<MainScreenAdmin />} />
          <Route path="monthly-pay" element={<MonthlyPay />} />
          <Route path="training-points" element={<TrainingPointsList />} />
          <Route path="classes" element={<ClassesList />} />
          <Route path="classes/create-edit" element={<ClassesCreateEdit />} />
          <Route path="classes/create-edit/:id" element={<ClassesCreateEdit />} />
          <Route path="buy-user-licences/:id" element={<BuyUserLicences />} />
          <Route path="main-screen-admin-company" element={<MainScreenAdminCompany />} />
          <Route path="users-licences-list" element={<UserLicencesList />} />
          <Route path="users-request-access" element={<UserRequestAccess />} />
          <Route path="reports-wrapper-screen" element={<ReportsWrapperScreen />} />
          <Route path="reports-user-reservations" element={<ReservationsByUser />} />
          <Route path="reports-allies-weekly-pay" element={<ReportAlliesWeeklyPay />} />


        </Routes>
      </div>

    </div>
  );
}

export default App;
