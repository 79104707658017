import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../app/hooks';
import DumbellSvg from '../assets/dumbell.svg';
import ReportSvg from '../assets/report.svg';
import ReportsSvg from '../assets/reports.svg';

// const API = BASEAPI?.axi
function ReportsWrapperScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const goToUserReservations= async () => {
        navigate('/reports-user-reservations');
    };

    const goToWeeklyPay= async () => {
        navigate('/reports-allies-weekly-pay');
    };
return (
        <div className="flex w-full h-full">
            {
            <div className="flex w-1/2 bg-min-gray items-center justify-center" onClick={goToUserReservations}>
                <div className='flex flex-col cursor-pointer'>
                    <h1 className='section'>
                        Asistencias por usuario
                    </h1>
                    <img className="max-h-24 mr-2" src={ReportsSvg} alt="reports" />

                </div>
            </div>
            }
            <div className="flex w-1/2 bg-light-gray items-center justify-center" onClick={goToWeeklyPay}>
                <div className='flex flex-col cursor-pointer'>
                    <h1 className='section'>
                        Liquidación Semanal Aliados
                    </h1>
                    <img className="max-h-24 mr-2" src={ReportsSvg} alt="reports" />

                </div>
            </div>
        </div>
    );
}

export default ReportsWrapperScreen;