// returns appropriate config based on env VITE_APP_CONFIG

// import a reference to our app config interface:
import { IAppConfig } from './models/AppConfig.interface';

// import reference to configFilesMap
import { configFilesMap } from './config-files-map';

export const getConfigFile = (): any => {
  let localAppConfig = configFilesMap.get('dev') as IAppConfig;
  if (
    process.env.REACT_APP_CONFIG_KEY &&
    typeof configFilesMap.get(process.env.REACT_APP_CONFIG_KEY)
  ) {
    localAppConfig =
      configFilesMap.get(process.env.REACT_APP_CONFIG_KEY) ??
      (configFilesMap.get(process.env.REACT_APP_CONFIG_KEY) as IAppConfig);
  }
  return localAppConfig;
};

const appConfig = getConfigFile();
export { appConfig };
