import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router';
import { setClass, setClassByAttr } from '../../app/classesReducer';
import { IClase } from './clase.interface';
import { useParams } from 'react-router-dom';
import GCInput from '../../primitives/inputs/GCInput.primitive';
import { classesGetDato, classesUpdate } from '../../http/classes-services';
import { ITipoEntreno } from '../Activities/TipoEntreno.interface';
import { setActivities } from '../../app/activitiesReducer';
import { activitiesGetDatos } from '../../http/activities-services';
import { PAGINA_INICIAL } from '../RegisterUserAccess/RegisterUserAccess';
import GCDropdown from '../../primitives/dropdowns/GCDropdown.primitive';
import GCPrimaryButton from '../../primitives/buttons/GCPrimaryButton';
import GCTextArea from '../../primitives/inputs/GCTextArea.primitive';

function ClassesCreateEdit() {
    const classToEdit: IClase = useAppSelector((state) => state?.classes?.class)

    const activities: Array<ITipoEntreno> = useAppSelector((state) => state?.activities?.activities)

    const [activitiesOption, setActivitiesOption] = useState<Array<{ key: string, description: string }>>([]);
    const [selectedActivity, setSelectedActivity] = useState<{ key: string, description: string }>({
        key: '0',
        description: 'Selecciona la actividad por favor'
    });

    const [disabledState, setDisabledState] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    let tmpActivities: Array<{ key: string, description: string }> = []

    let { id } = useParams();

    const navigate = useNavigate();

    const fetchClass = async () => {
        if (id) {

            const classTmp = await classesGetDato(id);
            dispatch(setClass(classTmp));
            const keyValueClass = {
                key: classTmp.id,
                description: classTmp.descripcion
            }
            onSelectedOptionChangeFn(keyValueClass)
        }
    }


    const fetchActivities = async () => {
        const activitiesTmp = await activitiesGetDatos('', PAGINA_INICIAL);
        tmpActivities = [];
        /*activitiesTmp.foreach((activity: any) => {
           
        })
        */

        activitiesTmp.forEach((tmpAct: any) => {
            const activityTmp = {
                description: '',
                key: ''
            };
            activityTmp.description = tmpAct.descripcion;
            activityTmp.key = tmpAct.id;

            tmpActivities.push(activityTmp);

        });

        setActivitiesOption(tmpActivities);
        dispatch(setActivities(activitiesTmp));
    }

    useEffect(() => {
        fetchClass();
        fetchActivities();
    }, [1]);

    const onSelectedOptionChangeFn = (selectedActivityTmp: any) => {
        setSelectedActivity(selectedActivityTmp);
    }



    const handleSubmit = async () => {
        let formattedData = Object.assign({}, classToEdit)
        formattedData.tipo_entreno = selectedActivity.key;
        formattedData.tipo_entreno = selectedActivity.key;
        formattedData.entrenador = classToEdit.entrenador.id;
        await classesUpdate(`` + classToEdit.id, formattedData)

    };

    return (
        <div className='flex my-4 justify-center gap-4 w-full '>
            <div className='flex flex-col gap-4 w-1/3 '>
                <div className="flex flex-col gap-2 p-4  justify-center w-full ">
                    <h1 className='font-sans text-xl text-bold'>Crear/Editar clases</h1>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Actividad</label>
                        <GCDropdown value={selectedActivity} options={activitiesOption} onSelectedOptionChange={onSelectedOptionChangeFn} containerClassName="w-full" />

                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="monthlyfee-cedula">Descripcion</label>
                        <GCInput id="descripcion" type="text" name="descripcion" previousValue={classToEdit?.descripcion} placeholder="" onChange={(newValue) => {
                            // setCost('costo_max_cuatro_usos', newValue);
                            dispatch(setClassByAttr({
                                attr: 'descripcion',
                                value: newValue
                            }));

                        }} />
                    </div>

                    <div className="flex flex-col mb-4">
                        <label className="font-bold" htmlFor="descripcion-larga">Descripcion Larga</label>
                        <GCTextArea id="descripcion-larga" type="text" name="descripcion-larga" previousValue={classToEdit?.descripcion_larga} placeholder="" onChange={(newValue) => {
                            dispatch(setClassByAttr({
                                attr: 'descripcion_larga',
                                value: newValue
                            }));

                            // setCost('costo_max_cuatro_usos', newValue);
                        }} />
                    </div>

                    <div className='flex w-full'>
                        <GCPrimaryButton disabled={disabledState} text={'Guardar'} id={'next'} onClick={handleSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ClassesCreateEdit;