import React from 'react';
import { useNavigate } from 'react-router';
import ReportSvg from '../assets/report.svg';
import UsersSvg from '../assets/users.svg';

// const API = BASEAPI?.axi
function MainScreenAdminCompany() {
    const navigate = useNavigate()
    const goToCompanyUsers = async () => {
        navigate('/users-licences-list');
    };

    const goToMonthlyPay = async () => {
        navigate(`/buy-user-licences/1`)
    };

    return (
        <div className="flex w-full h-full">
            <div className="flex w-1/2 bg-min-gray items-center justify-center" onClick={goToCompanyUsers}>
                <div className='flex flex-col cursor-pointer'>
                    <h1 className='section'>
                        Usuarios y Licencias
                    </h1>
                    <img className="max-h-24 mr-2" src={UsersSvg} alt="users" />

                </div>
            </div>
            <div className="flex w-1/2 bg-light-gray items-center justify-center" onClick={goToMonthlyPay}>
                <div className='flex flex-col cursor-pointer'>
                    <h1 className='section'>
                      Historial de pagos
                    </h1>
                    <img className="max-h-24 mr-2" src={ReportSvg} alt="reportSvg" />
                </div>
            </div>
        </div>
    );
}

export default MainScreenAdminCompany;