import React, { useEffect, useState } from 'react';

import { DropDownListContainer, DropDownHeader, DropDownList, ListItem } from './GCDropdownStyles';
import dropdownArrow from '../../components/assets/down.png';
interface IOption { key: string; description: string, descripcion?: string, id?: string }
type Props = {
  options: Array<string> | Array<IOption>;
  onSelectedOptionChange: (option: any) => void;
  value: IOption;
  containerClassName?: string;
};
const GCDropdown = ({ options, onSelectedOptionChange, value, containerClassName }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  
  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = (value: IOption | string) => () => {
    if (typeof value == 'string') {
      return;
    }
    setSelectedOption(value);
    toggling();
    onSelectedOptionChange(value);
  };


  const getDescription = (option: IOption | string) => {
    if (typeof option == 'string') {
      return '';
    }
    if (option.descripcion) {
      return option.descripcion;
    }
    return option.description;
  }

  const getId = (option: any) => {
    if (option.id) {
      return option.id;
    }
    return option.key;
  }


  useEffect(() => {
    // setSelectedOption(value);
  }, [value]);

  return (
    <div
      className={
        'align-center relative flex rounded bg-white py-2 px-4 ' +
        containerClassName
      }
    >
      <DropDownHeader onClick={toggling}>
        {getDescription(selectedOption) || ''}
        <img className="h-4" src={dropdownArrow} alt="dropdownIcon" />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList className="max-h-96 overflow-y-auto overflow-x-hidden">
            {options.map((currentOption) =>

              <ListItem onClick={onOptionClicked(currentOption)} key={Math.random()}>
                {getDescription(currentOption)}
              </ListItem>

            )}
          </DropDownList>
        </DropDownListContainer>
      )}
    </div>
  );
};

export default GCDropdown;
