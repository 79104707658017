type GCPrimaryButton = {
  disabled: boolean;
  text: string;
  id: string;
  btnType?: 'button' | 'submit' | 'reset' | undefined;
  onClick: () => void;
};
const GCPrimaryButton = ({ disabled, onClick, id, text, btnType }: GCPrimaryButton) => {
  return (
    <button
      id={id}
      disabled={disabled}
      className="flex w-full flex-col items-center rounded-md bg-primary px-16 py-2 text-black disabled:cursor-not-allowed disabled:text-white disabled:bg-primary-disabled"
      onClick={onClick}
      type={btnType ? btnType : undefined}
    >
      {text}
    </button>
  );
};
export default GCPrimaryButton;
