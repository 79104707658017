import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import GCPrimaryButton from '../../primitives/buttons/GCPrimaryButton';
import { loginRequest, getUserProfile } from '../../http/authentication-services';
import { useAppDispatch } from '../../app/hooks';
import { logIn, setUserProfile } from '../../app/userReducer';
import GCInput from '../../primitives/inputs/GCInput.primitive';
import UserSvg from '../assets/user.svg';
import { getTrainingPointByAdminId } from '../../http/satelite-services';
import { setTrainingPoint } from '../../app/trainingPointReducer';
import { ROLE_ADMIN_COMPANY, ROLE_SUPER_ADMIN } from '../../constants/Login.constants';
import GCSpinner from '../../primitives/spinner/GCSpinner';
import GCDropdown from '../../primitives/dropdowns/GCDropdown.primitive';
import { MEDELLIN_KEY, PEREIRA_KEY, QA_KEY } from '../../environment';

export const goToMainPage = (userData: any, navigate: any) => {
    if (userData?.roles.includes(ROLE_SUPER_ADMIN)) {
        navigate('/main-screen-admin');

    } else if (userData?.roles.includes(ROLE_ADMIN_COMPANY)) {
        navigate(`/main-screen-admin-company`)

    } else {
        navigate('/main-screen');
    }


}
// const API = BASEAPI?.axi
function Login() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const [currentEnv, setCurrentEnv] = useState<{ key: string, description: string }>({
        key:  QA_KEY,
        description: 'Pruebas'
    });
    const [environments, setEnvironments] = useState<Array<{ key: string, description: string }>>([
        {
            key: QA_KEY,
            description: 'Pruebas'
        },
        {
            key: PEREIRA_KEY,
            description: 'Pereira'
        },

        {
            key: MEDELLIN_KEY,
            description: 'Medellin'
        },
    ]);

    
    useEffect(() => {
        window.config = { environment: 'QA' }

    }, [1]);
    const handleSubmit = async () => {
        setLoading(true);

        try {
            const tokenData = await loginRequest({ username: email, password })
            await localStorage.setItem('user-token', JSON.stringify(tokenData.token))
            const userData = await getUserProfile()
            await localStorage.setItem('user-profile', JSON.stringify(userData))
            dispatch(setUserProfile(userData));
            dispatch(logIn());
            sessionStorage.setItem('loggedIn', 'true');
            goToMainPage(userData, navigate);
            if (!userData?.roles.includes(ROLE_SUPER_ADMIN) && !userData?.roles.includes(ROLE_ADMIN_COMPANY)) {
                const trainingPointData = await getTrainingPointByAdminId(userData.id)
                await localStorage.setItem('training-point', JSON.stringify(trainingPointData))
                dispatch(setTrainingPoint(trainingPointData));
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [disabledState, setDisabledState] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const updateCTAState = (emailVal: string, passwordVal: string) => {
        if (emailVal && emailVal.length > 0 && passwordVal && passwordVal.length > 0) {
            setDisabledState(false);
        } else {
            setDisabledState(true);
        }
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            if (email && password) {
                handleSubmit();
            }
        }
    }

    return (
        <div className="flex items-center justify-center w-full relative">
            <div className='h-20 w-20 -top-8 absolute rounded-3xl bg-md-gray flex items-center justify-center'>
                <img className="max-h-8" src={UserSvg} alt="userIcon" />
            </div>
            <div className="px-2 py-1 flex flex-col gap-y-2.5 items-center border-black px-4 py-8 bg-light-gray w-1/4">

                <div className="flex flex-col w-full">
                    <label className="" htmlFor="login-email">Email</label>
                    <GCInput id="login-email" type="email" name="login-email" previousValue="" placeholder="" onChange={(newValue) => {
                        setEmail(newValue);
                        updateCTAState(newValue, password);
                    }}
                        onKeyPressFn={handleKeyPress}
                    />
                </div>
                <div className="flex flex-col w-full">
                    <label htmlFor="login-password">Contraseña</label>

                    <GCInput id="login-password" type="password" name="login-password" previousValue="" placeholder="" onChange={(newValue) => {
                        setPassword(newValue);
                        updateCTAState(email, newValue);
                    }}
                        onKeyPressFn={handleKeyPress}
                    />
                </div>
                <div className='flex w-full'>
                    <GCDropdown value={currentEnv} options={environments} onSelectedOptionChange={(option) => {
                        if (option.key) {
                            window.config.environment = option.key;
                        }
                    }} containerClassName="w-full" />

                </div>
                <div className="flex  w-full" >
                    <GCPrimaryButton disabled={disabledState} text={'Entrar'} id={'next'} onClick={handleSubmit} />
                </div>

                {loading ? (
                    <div className="flex mt-2 justify-center w-full">
                        <GCSpinner></GCSpinner>
                    </div>
                ) : ''}
            </div>
        </div>
    );
}

export default Login;