

import { getConfigFile } from '../app-config';
import BaseAPI from './api';

const endpoint = 'usuarios';
const API = BaseAPI?.axiosInstance;
const env = getConfigFile().global;

const getTokenHeaders = () => {
    let unparsedToken = localStorage.getItem('user-token');
    if (!unparsedToken) {
        unparsedToken = ' ';
    }
    return {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(unparsedToken)}`

    }
}
export const usersGetCompany = async (search_term: string, pagina: string, empresa: string) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('user-token')
    };
    const response = await API.get(`${env.authpath}/${endpoint}`, {
        headers: getTokenHeaders(),
        params: {
            search_term,
            pagina,
            empresa
        }
    }).then(
        (res) => res
    );
    return response.data;
};

export const postRequestAccess = async (body: any) => {
    const headers = getTokenHeaders();
    const response = await API.post(`${env.authpath}/${endpoint}/solicituds`, body, { headers: getTokenHeaders() });
    return response;
};
