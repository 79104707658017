

import { getConfigFile } from '../app-config';
import { getEnvironment } from '../environment';
import BaseAPI from './api';

const endpoint = 'clases';
const API = BaseAPI?.axiosInstance;
const env = getConfigFile().global;

const getTokenHeaders = () => {
    let unparsedToken = localStorage.getItem('user-token');
    if (!unparsedToken) {
        unparsedToken = ' ';
    }
    return {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(unparsedToken)}`

    }
}


export const classesGetDatos = async (search_term: string, pagina: string) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('user-token')
    };
    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}`, {
        headers: getTokenHeaders(),
        params: {
            search_term,
            pagina
        }
    }).then(
        (res) => res.data
    );
    return response.data;
};


export const classesGetDato = async (id: string) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('user-token')
    };
    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}/${id}`, {
        headers: getTokenHeaders(),
        params: {
        }
    }).then(
        (res) => res
    );
    return response.data;
};

export const classesUpdate = async (classId: string, classBody: any) => {
    const response = await API.put(`${getEnvironment().baseUrl}${endpoint}/${classId}`, classBody, {
        headers: getTokenHeaders(),
    }).then((res) => res);
    return response;
};