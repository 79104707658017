import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
    name: 'costByAssistanceReducer',
    initialState: {
        precioPuntoEntreno: {
            costo_max_cuatro_usos: '',
            costo_max_ocho_usos: '',
            costo_max_doce_usos: '',
            costo_max_quince_usos: '',
            costo_mas_quince_usos: '',
            costo_clase: '',
            costo_mensualidad: '',
            satelite_id: '',
        }
    },
    reducers: {
        setPrecioPuntoEntreno: (state, action) => {
            state.precioPuntoEntreno = action.payload;
        }
    }
});

export const { setPrecioPuntoEntreno } = customersSlice.actions;

export default customersSlice.reducer;
