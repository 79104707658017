import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router';
import { classesGetDatos } from '../../http/classes-services';
import { PAGINA_INICIAL } from '../RegisterUserAccess/RegisterUserAccess';
import { setClasses } from '../../app/classesReducer';
import GrupoEntrenoImg from '../assets/kit/grupoEntreno.jpeg';
import { IClase } from './clase.interface';

function ClassesList() {
    const classes: Array<IClase> = useAppSelector((state) => state?.classes?.classes)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const fetchClasses = async () => {
        const classesTmp = await classesGetDatos('', PAGINA_INICIAL);
        dispatch(setClasses(classesTmp));
    }


    useEffect(() => {
        fetchClasses();
    }, [1]);

    const goToClass = (id: number | undefined) => {
        if (id) {
            navigate(`/classes/create-edit/${id}`);
        }
        return;
    }

    return (
        <div className="flex flex-col w-full h-full py-8 items-center ">
            <div className="flex flex-col gap-4 items-center w-full">
                <h1 className=''>Clases</h1>
                <div className="flex flex gap-4 w-full">

                    {classes.map((classTmp) =>
                    (<div className='flex border border-solid border-black rounded pr-2 cursor-pointer' key={Math.random()} onClick={() => goToClass(classTmp.id)}>
                        <div className='flex gap-2'>
                            <img className="h-24" src={GrupoEntrenoImg} alt="classImg" />

                        </div>
                        <div className='flex flex-col py-4'>
                            <h2 className='mb-2'>
                                {classTmp.descripcion}
                            </h2>
                        </div>

                    </div>)

                    )
                    }
                </div>

                <div className='flex'>

                </div>
            </div>
        </div>
    )
}

export default ClassesList;