import { getReservasByUser, getTotalRevenueForSpace } from "../../http/reserva-services";
import React, { useEffect, useState } from 'react';
import GCMonthylPayDropdown from "../../primitives/dropdowns/GCMonthylPayDropdown.primitive";
import { periodoGetDatos } from "../../http/periodo-service";
import { PAGINA_INICIAL } from "../RegisterUserAccess/RegisterUserAccess";
import { MonthDropdownI, MonthlyPayDropdownI } from "../MonthlyPay/monthlyPayDropdown.interface";
import { formatCurrencyNumber } from "../MonthlyPay/MonthlyPay";

function ReportAlliesWeeklyPay() {
    const [monthlyPays, setMonthlyPays] = useState<Array<MonthlyPayDropdownI>>([]);
    const [selectedMonth, setSelectedMonth] = useState<MonthDropdownI>({
        id: '',
        key: '',
        descripcion: '',
        weeks: []
    });

    const [selectedWeek, setSelectedWeek] = useState<MonthlyPayDropdownI>({
        id: '',
        key: '',
        descripcion: '',
        total: 0,
        pagado: false
    });

    const [totalReservationsByUser, setTotalReservationsByUser] = useState<Array<{
        id: number,
        descripcion: string,
        total: number
    }>>([]);

    const fetchData = async (period: MonthlyPayDropdownI) => {
        let reservasByUser = await getTotalRevenueForSpace(period.id);
        setTotalReservationsByUser(reservasByUser);

    }
    const getData = async (period: MonthlyPayDropdownI) => {
        if (period.id) {
            setSelectedWeek(period);
            fetchData(period);
        }

    }

    const fetchMonthlyPays = async () => {
        const tmpPeriodos = await periodoGetDatos('', PAGINA_INICIAL);
        let acum = 0;

        tmpPeriodos.map((trainingPoint: any) => {
            acum++;
            trainingPoint.key = acum;
            return trainingPoint;
        })
        setMonthlyPays(tmpPeriodos);
    }


    useEffect(() => {
        fetchMonthlyPays();
    }, [1]);

    const onSelectedOptionChangeFn = async (period: MonthDropdownI) => {
        setSelectedMonth(period);
    }


    return (
        <div className="flex flex-col w-full h-full py-8 px-4 bg-min-gray gap-4">
            <h1>Pago para aliados</h1>
            <div>
                <label className="font-bold" htmlFor="monthlypay-month">Semana</label>
                <div className="flex w-1/3">
                    {
                        <GCMonthylPayDropdown value={'selecciona la semana por favor'} options={monthlyPays} onSelectedOptionChange={onSelectedOptionChangeFn} containerClassName="w-full" />
                    }               </div>
            </div>

            <div className="flex">
                {
                    selectedMonth?.weeks?.map((weekTmp, index) => (
                        <div
                            className={'py-4 mr-3 bg-white max-w-xs px-2 border-solid border border-light-gray rounded-md ' +
                                (
                                    weekTmp.pagado ? 'border-light-green bg-light-green text-color-white' : 'border-light-gray bg-light-yellow'
                                )}

                            onClick={(period) => getData(weekTmp)}>
                            <small>
                                {selectedWeek.id == weekTmp.id ? (<span className=""><b>{weekTmp.descripcion}</b></span>) :
                                    (
                                        <span className="text-black">
                                            {weekTmp.descripcion}</span>
                                    )
                                }
                            </small>
                            <div>
                                <small>
                                    {weekTmp.pagado ? 'Pagado' : 'Pendiente'}
                                </small>
                            </div>
                        </div>

                    ))}
            </div>
            <div className="flex w-full">
                <div className="flex w-full flex-col gap-2 bg-white px-4 py-2">


                    <div className="flex w-full ">
                        <div className="flex w-1/2 font-bold">
                            <label>
                                Descripción
                            </label>
                        </div>

                        <div className="flex w-1/2 font-bold">
                            <label htmlFor="asistencias">
                                Pago
                            </label>
                        </div>

                    </div>
                    {
                        totalReservationsByUser?.map((totalSpace, index) => (
                            <div className="flex w-full">
                                <div className="flex w-1/2">{totalSpace?.descripcion} </div>

                                <div className="flex w-1/2">
                                    {formatCurrencyNumber(totalSpace.total)}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}


export default ReportAlliesWeeklyPay;