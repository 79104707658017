import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { usersGetCompany } from '../../http/user-services';
import { PAGINA_INICIAL } from '../RegisterUserAccess/RegisterUserAccess';
import GCSpinner from '../../primitives/spinner/GCSpinner';
import { setCompanyPlan, setCompanyUsers } from '../../app/companiesReducer';
import { getBorder } from '../MonthlyPay/MonthlyPay';
import { getPlanByUserId } from '../../http/monthly-pay-services';
import GCPrimaryButton from '../../primitives/buttons/GCPrimaryButton';
import { IMonthlyFee } from '../RegisterUserAccess/monthlyFee.interface';

function UserLicencesList() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const company = useAppSelector((state) => state?.user?.userProfile?.empresa)
    const user = useAppSelector((state) => state?.user?.userProfile)
    const users = useAppSelector((state) => state?.companies.users)
    const plan = useAppSelector((state) => state?.companies.plan)
    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(-1);
    const [disabledState, setDisabledState] = useState<boolean>(true);

    const setCompanyUsersFn = async (companyId: number) => {
        setLoading(true);
        let companyUsers = await usersGetCompany('', PAGINA_INICIAL, '' + companyId);
        dispatch(setCompanyUsers(companyUsers.data));
        setTotal(companyUsers.total);
        setLoading(false);
        setDisabledState(true);

    }


    const setPlanFn = async () => {
        setLoading(true);
        let planTmp = await getPlanByUserId('' + user.id);
        dispatch(setCompanyPlan(planTmp));
        setLoading(false);
        setDisabledBtn(planTmp);
    }

    const setDisabledBtn = (planTmp: IMonthlyFee) => {
        if (getAvailableLicenses(planTmp) > 0) {
            setDisabledState(false);
        }
    }
    useEffect(() => {
        if (company.id > 0) {
            setCompanyUsersFn(company.id);
            setPlanFn();
        }
    }, [1]);

    const navigateToRequestAccess = () => {
        navigate(`/users-request-access`)
    }


    const getAvailableLicenses = (plan: any) => {
        if(total<0){
            return 0;
        }
        if (plan?.mensualidad) {
            return plan?.mensualidad?.nro_licencias - total;
        }
        return 0;
    }
    return (
        <div className="flex flex-col items-center w-full h-full py-8 bg-light-gray">
            {loading ? (<div className="flex">
                <GCSpinner></GCSpinner>
            </div>) : ''
            }
            <div className='flex w-3/4 justify-end'>
                <div className="flex w-auto">
                    <GCPrimaryButton disabled={disabledState} text={'Añadir Usuario'} id={'next'} onClick={navigateToRequestAccess} />
                </div>
            </div>
            {users?.length > 0 ? (
                <div className="flex my-4 flex-col gap-2 bg-white py-2 px-4 w-3/4">


                    <div className="flex w-full ">
                        <div className="flex w-1/4 font-bold">
                            <label>
                                Nombre
                            </label>
                        </div>
                        <div className="flex w-1/4 font-bold">
                            <label htmlFor="cedula">
                                Documento
                            </label>
                        </div>
                        <div className="flex w-1/4 font-bold">
                            <label htmlFor="asistencias">
                                Correo Electrónico
                            </label>
                        </div>
                        <div className="flex w-1/4 font-bold">
                            <label htmlFor="asistencias">
                                Teléfono
                            </label>
                        </div>
                    </div>

                    {
                        users.map((user, index) => (
                            <div className={"flex w-full  " + getBorder(index, users.length)} key={Math.random()}>
                                <div className="flex w-1/4">{user?.nombre}</div>
                                <div className="flex w-1/4">CC {user?.cedula}</div>
                                <div className="flex w-1/4">{user?.email}</div>
                                <div className="flex w-1/4 justify-end">{user?.telefono}</div>
                            </div>
                        ))

                    }


                </div>
            ) : ""
            }


            <div className="flex flex-col gap-2 w-3/4">
                {plan?.mensualidad?.nro_licencias > 0 ? (
                    <>
                        <div className="flex w-full justify-end">
                            <b> {plan?.mensualidad?.titulo} del {plan?.fecha_inicio.substring(0, 10)} hasta {plan?.fecha_fin.substring(0, 10)}
                            </b>
                        </div>
                        <div className="flex w-full justify-end">
                            <b>{getAvailableLicenses(plan)} licencias disponibles de {plan?.mensualidad?.nro_licencias}
                            </b>
                        </div>

                    </>
                ) : ''
                }
            </div>
        </div>
    );
}

export default UserLicencesList;