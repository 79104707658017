import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../app/hooks';
import DumbellSvg from '../assets/dumbell.svg';
import ReportSvg from '../assets/report.svg';

// const API = BASEAPI?.axi
function MainScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const goToRegisterAccess = async () => {
        navigate('/register-user-access');
    };
 
    const goToMonthlyPay = async () => {
        navigate('/monthly-pay');
    };

    return (
        <div className="flex w-full h-full">
            <div className="flex w-1/2 bg-min-gray items-center justify-center" onClick={goToRegisterAccess}>
                <div className='flex flex-col cursor-pointer'>
                    <h1 className='section'>
                        Registrar entrada de usuario
                    </h1>
                    <img className="max-h-24 mr-2" src={DumbellSvg} alt="dumbelIcon" />

                </div>
            </div>
            <div className="flex w-1/2 bg-light-gray items-center justify-center" onClick={goToMonthlyPay}>
                <div className='flex flex-col cursor-pointer'>
                    <h1 className='section'>
                        Liquidación Semanal
                    </h1>
                    <img className="max-h-24 mr-2" src={ReportSvg} alt="reportSvg" />
                </div>
            </div>
        </div>
    );
}

export default MainScreen;