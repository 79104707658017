import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../app/hooks';
import DumbellSvg from '../assets/dumbell.svg';
import ReportSvg from '../assets/report.svg';
import ReportsSvg from '../assets/reports.svg';

// const API = BASEAPI?.axi
function MainScreenAdmin() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const goToReports = async () => {
        navigate('/reports-wrapper-screen');
    };

    const goToMonthlyPay = async () => {
        navigate('/monthly-pay');
    };

    return (
        <div className="flex w-full h-full">
            {
            <div className="flex w-1/2 bg-min-gray items-center justify-center" onClick={goToReports}>
                <div className='flex flex-col cursor-pointer'>
                    <h1 className='section'>
                        Reportes
                    </h1>
                    <img className="max-h-24 mr-2" src={ReportsSvg} alt="reports" />

                </div>
            </div>
            }
            <div className="flex w-1/2 bg-light-gray items-center justify-center" onClick={goToMonthlyPay}>
                <div className='flex flex-col cursor-pointer'>
                    <h1 className='section'>
                        Liquidación Semanal
                    </h1>
                    <img className="max-h-24 mr-2" src={ReportSvg} alt="reportSvg" />
                </div>
            </div>
        </div>
    );
}

export default MainScreenAdmin;