import { useState } from 'react';
import { DropDownListContainer, DropDownHeader, DropDownList, ListItem } from './GCDropdownStyles';
import dropdownArrow from '../../components/assets/down.png';
import { MonthlyPayDropdownI } from '../../components/MonthlyPay/monthlyPayDropdown.interface';
import { formatCurrencyNumber } from '../../components/MonthlyPay/MonthlyPay';

type Props = {
    options: Array<string> | Array<MonthlyPayDropdownI>;
    onSelectedOptionChange: (option: any) => void;
    value: any;
    containerClassName?: string;
};
const GCMonthylPayDropdown = ({ options, onSelectedOptionChange, value, containerClassName }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value);
    const [selectedOptionKey, setSelectedOptionKey] = useState(value);

    const toggling = () => setIsOpen(!isOpen);
    const onOptionClicked = (value: any) => () => {
        setSelectedOption(value);
        setSelectedOptionKey(value.key);
        toggling();
        onSelectedOptionChange(value);
    };

    const getContent = (currentOption: MonthlyPayDropdownI) => {
        return (
            <>
                {currentOption.descripcion} {currentOption.total ? '-' : ''} <b>{currentOption.total ? 'Total:' : ''} {formatCurrencyNumber(currentOption.total)}</b>

                <b>{currentOption.pagado ? (<span className='text-light-green'>(Pagado)</span>) : (<span>(Pendiente)</span>)}</b>
            </>
        )
    }

    const getSelectedContent = (currentOption: MonthlyPayDropdownI) => {
        return (
            <>
                {currentOption.descripcion ? currentOption.descripcion : 'Selecciona la semana por favor'}             </>
        )
    }

    return (
        <div
            className={
                'align-center relative flex rounded bg-white py-2 px-4 ' +
                containerClassName
            }
        >
            <DropDownHeader onClick={toggling}>
                {getSelectedContent(selectedOption)}
                <img className="h-4" src={dropdownArrow} alt="dropdownIcon" />
            </DropDownHeader>
            {isOpen && (
                <DropDownListContainer>
                    <DropDownList className="max-h-96 overflow-y-auto overflow-x-hidden">
                        {options.map((currentOption) =>
                            typeof currentOption == 'string' ? (
                                <ListItem onClick={onOptionClicked(currentOption)} key={Math.random()}>
                                    {currentOption}
                                </ListItem>
                            ) : (
                                <ListItem onClick={onOptionClicked(currentOption)} key={Math.random()}>

                                    {getContent(currentOption)}
                                </ListItem>
                            )
                        )}
                    </DropDownList>
                </DropDownListContainer>
            )}
        </div>
    );
};

export default GCMonthylPayDropdown;
