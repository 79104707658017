import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
    name: 'classes',
    initialState: {
        classes: [],
        class: {
            id: 0,
            descripcion: '',
            descripcion_larga: '',
            tipo_entreno: '',
            calificacion_promedio: 0,
            numero_calificaciones: 0,
            is_virtual: false
        }

    },
    reducers: {
        setClasses: (state, action) => {
            state.classes = action.payload;
        },
        setClass: (state, action) => {
            state.class = action.payload;
        },
        setClassByAttr: (state, action) => {
            let index: 'descripcion' | 'descripcion_larga' = action.payload.attr;
            state.class[index] = action.payload.value;
        }
    }
});

export const { setClasses } = customersSlice.actions;
export const { setClass } = customersSlice.actions;
export const { setClassByAttr } = customersSlice.actions;

export default customersSlice.reducer;
