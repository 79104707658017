
import BaseAPI from './api';
import { getConfigFile } from '../app-config';
import { getEnvironment } from '../environment';

const env = getConfigFile().global;


const globalHeaders = {
    'Content-Type': 'application/json'
};

const API = BaseAPI?.axiosInstance;


const getTokenHeaders = () => {
    let unparsedToken = localStorage.getItem('user-token');
    if (!unparsedToken) {
        unparsedToken = ' ';
    }
    return {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(unparsedToken)}`

    }
}
export const postCostByAssistance = async (body: any) => {
    const headers = { ...globalHeaders };
    const response = await API.post(`${getEnvironment().baseUrl}preciopuntoentrenos`, body, { headers: getTokenHeaders() });
    return response;
};
