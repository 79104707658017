import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
    name: 'monthlyFeesReducer',
    initialState: {
        activeMonthlyPay: {
            fecha_inicio: '',
            fecha_fin: '',
            fecha_inicio_str: '',
            fecha_fin_str: '',
            mensualidad: { titulo: '' },
            cliente: { nombre: '' },
        }
    },
    reducers: {
        setActiveMonthlyPay: (state, action) => {
            state.activeMonthlyPay = action.payload;
        }
    }
});

export const { setActiveMonthlyPay } = customersSlice.actions;

export default customersSlice.reducer;
