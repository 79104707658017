import BaseAPI from './api';
import { getConfigFile } from '../app-config';
import {
    LoginUser,
    AuthenticationResponse,
    ErrorResponse,
    Auth3Response,
    AccessTokenResponse,
    LogoutRequestBody
} from '../app/models/auth/loginUser';
import { refreshTokenRequest } from '../app/authConstants';
import { getEnvironment } from '../environment';

const globalHeaders = {
    'Content-Type': 'application/json',
};

const endpoint = 'pagomensualidads';

const env = getConfigFile().global;

const API = BaseAPI?.axiosInstance;

const getTokenHeaders = () => {
    let unparsedToken = localStorage.getItem('user-token');
    if (!unparsedToken) {
        unparsedToken = ' ';
    }
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(unparsedToken)}`
    }
}

export const getByCedula = async (id: string) => {

    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}/by_cedula`, {
        headers: getTokenHeaders(),
        params: { cedula: id }
    }).then(
        (res) => res
    );
    return response.data;
};

export const hasAccess = async (id: string, entrenoId:number) => {

    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}/has_access`, {
        headers: getTokenHeaders(),
        params: { cedula: id ,
            entrenoId
        }
    }).then(
        (res) => res
    );
    return response.data;
};

export const getPlanByUserId = async (user: string) => {

    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}/by_user_id`, {
        headers: getTokenHeaders(),
        params: { user }
    }).then(
        (res) => res.data
    );
    return response.data;
};

