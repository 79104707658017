import GCMonthylPayDropdown from "../../primitives/dropdowns/GCMonthylPayDropdown.primitive";
import React, { useEffect, useState } from 'react';
import { MonthDropdownI, MonthlyPayDropdownI } from "./monthlyPayDropdown.interface";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { reservasTotalByPuntoEntreno, reservasTotalByPuntoEntrenoGroupedClass } from "../../http/reserva-services";
import GCSpinner from "../../primitives/spinner/GCSpinner";
import { periodoGetDatos, periodoMarkAsPaid } from "../../http/periodo-service";
import { PAGINA_INICIAL } from "../RegisterUserAccess/RegisterUserAccess";
import { ROLE_SUPER_ADMIN } from "../../constants";
import GCDropdown from "../../primitives/dropdowns/GCDropdown.primitive";
import GCPrimaryButton from '../../primitives/buttons/GCPrimaryButton';
import { satelitesGetDatos } from "../../http/satelite-services";
import { setTrainingPoints } from "../../app/trainingPointReducer";

export const getBorder = (index: number, listLength: number) => {
    if (index < (listLength - 1)) {
        return 'border-b border-light-gray';
    }
    return '';
}

interface totalTrainingPointI {
    totalByUsers: Array<any>,
    total: number,
    totalAsistencias: number;
};

export const formatCurrencyNumber = (numberToFormat: number) => {
    if (numberToFormat > 0) {
        var nf = new Intl.NumberFormat();
        return `$${nf.format(numberToFormat)}`;
    }
    return ``;
}
function MonthlyPay() {
    const dispatch = useAppDispatch();
    const trainingPoint = useAppSelector((state) => state?.trainingPoint?.trainingPoint)
    const userProfile = useAppSelector((state) => state?.user?.userProfile)
    const trainingPoints = useAppSelector((state) => state?.trainingPoint?.trainingPoints)
    const [totalByTrainingPoint, setTotalByTrainingPoint] = useState<totalTrainingPointI>({
        totalByUsers: [],
        total: 0,
        totalAsistencias: 0
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedMonth, setSelectedMonth] = useState<MonthDropdownI>({
        id: '',
        key: '',
        descripcion: '',
        weeks: []
    });

    const [selectedWeek, setSelectedWeek] = useState<MonthlyPayDropdownI>({
        id: '',
        key: '',
        descripcion: '',
        total: 0,
        pagado: false
    });

    const [monthlyPays, setMonthlyPays] = useState<Array<MonthlyPayDropdownI>>([]);
    const [adminTrainingPoint, setAdminTrainingPoint] = useState<{ id: string, descripcion: string }>({ id: '', descripcion: '' });
    const [disabledState, setDisabledState] = useState<boolean>(true);

    const onSelectedPointOptionChangeFn = async (trainingPoint: any) => {
        setAdminTrainingPoint(trainingPoint);
        if (selectedWeek.key) {
            fetchMonthlyPays(trainingPoint.id, selectedWeek);
        }
        setPeriodos();

    }

    const getTrainingPointId = () => {
        let trainingPointId = `${trainingPoint.id}`;
        if (userProfile.roles.includes(ROLE_SUPER_ADMIN)) {
            trainingPointId = adminTrainingPoint.id;
        }

        return trainingPointId;
    }

    const onSelectedOptionChangeFn = async (period: MonthDropdownI) => {
        setSelectedMonth(period);
    }

    const fetchMonthlyPays = async (trainingPointId: string, period: MonthlyPayDropdownI) => {
        setLoading(true);

        //let totalByTrainingPointTmp = await reservasTotalByPuntoEntreno(`${trainingPointId}`, `${period.id}`);
        let totalByTrainingPointTmp = await reservasTotalByPuntoEntrenoGroupedClass(`${trainingPointId}`, `${period.id}`);
        console.log(totalByTrainingPointTmp);
        setLoading(false);
        setTotalByTrainingPoint(totalByTrainingPointTmp);
        changeDisabledState(totalByTrainingPointTmp, period);

    }

    const changeDisabledState = (totalByTrainingPointTmp: totalTrainingPointI, period: MonthlyPayDropdownI) => {
        if (totalByTrainingPointTmp?.totalByUsers?.length > 0) {
            setDisabledState(false);
        } else {
            setDisabledState(true);
        }

        if (period.pagado) {
            setDisabledState(true);
        }

    }

    const setPeriodos = async () => {
        setLoading(true);
        const tmpPeriodos = await periodoGetDatos('', PAGINA_INICIAL);
        setLoading(false);
        let acum = 0;
        tmpPeriodos.map((trainingPoint: any) => {
            acum++;
            trainingPoint.key = acum;
            return trainingPoint;
        })
        setMonthlyPays(tmpPeriodos);
    }

    const fetchSatelites = async () => {
        const satelitesData = await satelitesGetDatos('', PAGINA_INICIAL);
        dispatch(setTrainingPoints(satelitesData.data));
    }


    useEffect(() => {
        if (trainingPoint.id > 0) {
            setPeriodos();
        }
        fetchSatelites();
    }, [1]);

    const handlePay = async () => {

        console.log(selectedWeek)
        if (selectedWeek.id) {
            setLoading(true);
            await periodoMarkAsPaid(selectedWeek.id, adminTrainingPoint.id, `${totalByTrainingPoint?.total}`);
            setPeriodos();
            setLoading(false);
            setDisabledState(true);
            onSelectedPointOptionChangeFn(trainingPoint)
            setSelectedWeek({
                id: '',
                key: '',
                descripcion: '',
                total: 0,
                pagado: false
            })
        }
    }

    const getData = async (period: MonthlyPayDropdownI) => {
        if (trainingPoint.id || userProfile.roles.includes(ROLE_SUPER_ADMIN)) {
            if (period.id) {
                setSelectedWeek(period);
                fetchMonthlyPays(getTrainingPointId(), period);
            }
        }
    }

    return (
        <div className="flex flex-col w-full h-full py-8 items-center bg-min-gray ">
            <div className="flex flex-col gap-2 w-3/4">
                <h1 className=''>Liquidación Semanal</h1>
                {userProfile.roles.includes(ROLE_SUPER_ADMIN) ? (
                    <>
                        <label className="font-bold" htmlFor="monthlypay-trainingPoint">Espacio</label>
                        <GCDropdown value={{ key: '1', description: 'selecciona el espacio por favor' }} options={trainingPoints} onSelectedOptionChange={onSelectedPointOptionChangeFn} containerClassName="w-1/2" />
                    </>
                ) : ''}
                <label className="font-bold" htmlFor="monthlypay-month">Semana</label>
                <div className="flex">
                    <GCMonthylPayDropdown value={'selecciona la semana por favor'} options={monthlyPays} onSelectedOptionChange={onSelectedOptionChangeFn} containerClassName="w-1/2" />
                    {userProfile.roles.includes(ROLE_SUPER_ADMIN) ? (

                        <div className="flex w-1/2 justify-end">
                            <div className="flex w-3/4">
                                <GCPrimaryButton disabled={disabledState} text={'Pagar'} id={'next'} onClick={handlePay} />
                            </div>
                        </div>
                    ) : ''}
                </div>
                <div className="flex">
                    {
                        selectedMonth?.weeks?.map((weekTmp, index) => (
                            <div
                                className={'py-4 mr-3 bg-white max-w-xs px-2 border-solid border border-light-gray rounded-md ' +
                                    (
                                        weekTmp.pagado ? 'border-light-green bg-light-green text-color-white' : 'border-light-gray bg-light-yellow'
                                    )}

                                onClick={(period) => getData(weekTmp)}>
                                <small>
                                    {selectedWeek.id == weekTmp.id ? (<span className=""><b>{weekTmp.descripcion}</b></span>) :
                                        (
                                            <span className="text-black">
                                                {weekTmp.descripcion}</span>
                                        )
                                    }
                                </small>
                                <div>
                                    <small>
                                        {weekTmp.pagado ? 'Pagado' : 'Pendiente'}
                                    </small>
                                </div>
                            </div>

                        ))}
                </div>
                {loading ? (<div className="">
                    <GCSpinner></GCSpinner>
                </div>) : ''
                }
                {totalByTrainingPoint?.totalByUsers?.length > 0 ? (
                    <div className="flex my-4 flex-col gap-2 bg-white py-2 px-4">


                        <div className="flex w-full ">
                            <div className="flex w-1/3 font-bold">
                                <label>
                                    Clase
                                </label>
                            </div>
                           
                            <div className="flex w-1/3 font-bold">
                                <label htmlFor="asistencias">
                                    Asistencias
                                </label>
                            </div>
                            <div className="flex w-1/3 font-bold">
                                <label htmlFor="total">
                                    Total
                                </label>
                            </div>
                        </div>

                        {
                            totalByTrainingPoint?.totalByUsers.map((classTmp, index) => (
                                <div className={"flex w-full  " + getBorder(index, totalByTrainingPoint?.totalByUsers?.length)} key={Math.random()}>
                                    <div className="flex w-1/3">{classTmp?.descripcion} ({classTmp?.id})</div>
                                    <div className="flex w-1/3">{classTmp?.asistencias}</div>
                                    <div className="flex w-1/3">{classTmp?.total ? formatCurrencyNumber(classTmp.total) : 0}</div>
                                </div>
                            ))

                        }


                    </div>
                ) : ""
                }

                {totalByTrainingPoint?.totalByUsers?.length === 0
                    && !loading
                    && selectedMonth.key ? (
                    <div className="flex my-4 gap-2">   <div className="flex w-full">
                        <h1>Esta semana no hay asistencias registradas</h1>
                    </div>
                    </div>
                ) : ""}
                <div className="flex flex-col gap-2">
                    {totalByTrainingPoint?.total > 0 ? (
                        <div className="flex w-full justify-end">
                            <b>Total {formatCurrencyNumber(totalByTrainingPoint?.total)}
                            </b>
                        </div>) : ''
                    }
                </div>
            </div >

        </div >
    )

}

export default MonthlyPay;