

import { getConfigFile } from '../app-config';
import { getEnvironment } from '../environment';
import BaseAPI from './api';

const endpoint = 'reservas';
const API = BaseAPI?.axiosInstance;
const env = getConfigFile().global;

const getTokenHeaders = () => {
    let unparsedToken = localStorage.getItem('user-token');
    if (!unparsedToken) {
        unparsedToken = ' ';
    }
    return {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(unparsedToken)}`

    }
}


export const reservaCreateMultipleTrainers = (cedula: string, trainings: Array<{ entreno_id: number, numeroInvitados: string }>): Promise<{ token: string }> => {

    const response = API.post<{ token: string }>(
        `${getEnvironment().baseUrl}${endpoint}/${cedula}/multiple_reserva_trainers`,
        trainings,
        {
            headers: getTokenHeaders(),
        }
    ).then((res) => res?.data);
    return response;
};


export const reservasTotalByPuntoEntreno = async (puntoEntrenoId: string, periodoId: string) => {

    const response = await API.get(`${getEnvironment().baseUrl}reserva/total_reserva`, {
        headers: getTokenHeaders(),
        params: {
            puntoEntrenoId,
            periodoId
        }
    }).then(
        (res) => res.data
    );
    return response.data;
};

export const reservasTotalByPuntoEntrenoGroupedClass = async (puntoEntrenoId: string, periodoId: string) => {

    const response = await API.get(`${getEnvironment().baseUrl}reserva/total_reserva_grouped_class`, {
        headers: getTokenHeaders(),
        params: {
            puntoEntrenoId,
            periodoId
        }
    }).then(
        (res) => res.data
    );
    return response.data;
};

export const getReservasByUser = async (periodId: string | undefined) => {
    const response = await API.get(`${getEnvironment().baseUrl}reserva/reservas_by_user`, {
        headers: getTokenHeaders(),
        params: {
            periodoId: periodId
        }
    }).then(
        (res) => res
    );
    return response.data;
};


export const getTotalRevenueForSpace = async (periodId: string | undefined) => {
    const response = await API.get(`${getEnvironment().baseUrl}reserva/total_grouped_punto`, {
        headers: getTokenHeaders(),
        params: {
            periodoId: periodId
        }
    }).then(
        (res) => res
    );
    return response.data;
};