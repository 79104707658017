import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import GCPrimaryButton from "../../primitives/buttons/GCPrimaryButton";
import GCInput from "../../primitives/inputs/GCInput.primitive";
import { setUserToRequestAccess } from '../../app/userReducer';
import GCSpinner from '../../primitives/spinner/GCSpinner';
import { postRequestAccess } from '../../http/user-services';
import { useNavigate } from 'react-router-dom';


function UserRequestAccess() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [nombre, setNombre] = useState<string>('');
    const [cedula, setCedula] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [telefono, setTelefono] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const company = useAppSelector((state) => state?.user?.userProfile?.empresa);
    const [disabledState, setDisabledState] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const setDisabledStateFn = (nombreTmp: string, cedulaTmp: string, emailTmp: string, passwordTmp: string) => {
        setDisabledState(true);
        if (nombreTmp && cedulaTmp && emailTmp && passwordTmp) {
            setDisabledState(false);
        }
    }
    const submitUser = async () => {
        let userData = {
            nombre,
            cedula,
            email,
            username: email,
            password,
            telefono,
            empresa: company.id
        }
        setLoading(true);

        await postRequestAccess(userData);
        setLoading(false);

        navigateToLicences();
    }

    const navigateToLicences = () => {
        navigate(`/users-licences-list`);
    }


    return (
        <div className='flex flex-col w-full h-full justify-center items-center py-8 '>
            {loading ? (<div className="">
                <GCSpinner></GCSpinner>
            </div>) : ''
            }
            <div className='flex flex-col gap-4 w-1/3 bg-min-gray rounded-lg py-6 px-4'>
                <div className="flex flex-col gap-2 p-4  justify-center w-full ">
                    <h1 className='font-sans text-xl text-bold'>Nuevo usuario</h1>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="user-nombre">Nombre</label>
                        <GCInput id="nombre" type="text" name="nombre" previousValue={''} placeholder="" onChange={(newValue) => {
                            setNombre(newValue);
                            setDisabledStateFn(newValue, cedula, email, password);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="user-cedula">Cedula</label>
                        <GCInput id="cedula-id" type="text" name="cedula" previousValue={''} placeholder="" onChange={(newValue) => {
                            setCedula(newValue);
                            setDisabledStateFn(nombre, newValue, email, password);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="user-telefono">Teléfono</label>
                        <GCInput id="telefono-id" type="text" name="telefono" previousValue={''} placeholder="" onChange={(newValue) => {
                            setTelefono(newValue);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="user-email">Correo eléctronico</label>
                        <GCInput id="email-id" type="text" name="email" previousValue={''} placeholder="" onChange={(newValue) => {
                            setEmail(newValue);
                            setDisabledStateFn(nombre, cedula, newValue, password);
                        }} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold" htmlFor="user-password">Contraseña</label>
                        <GCInput id="password-id" type="password" name="password" previousValue={''} placeholder="" onChange={(newValue) => {
                            setPassword(newValue);
                            setDisabledStateFn(nombre, cedula, email, newValue);
                        }} />
                    </div>
                    <div>
                        <GCPrimaryButton disabled={disabledState} text={'Guardar'} id={'save'} onClick={submitUser} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default UserRequestAccess;