

import { getConfigFile } from '../app-config';
import { getEnvironment } from '../environment';
import BaseAPI from './api';

const endpoint = 'satelites';
const API = BaseAPI?.axiosInstance;
const env = getConfigFile().global;

const getTokenHeaders = () => {
    let unparsedToken = localStorage.getItem('user-token');
    if (!unparsedToken) {
        unparsedToken = ' ';
    }
    return {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(unparsedToken)}`

    }
}
export const getTrainingPointByAdminId = async (id: string) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('user-token')
    };
    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}/by_admin_id`, {
        headers: getTokenHeaders(),
        params: { id }
    }).then(
        (res) => res
    );
    return response.data;
};


export const satelitesGetDatos = async (search_term: string, pagina: string) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('user-token')
    };

    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}`, {
        headers: getTokenHeaders(),
        params: {
            search_term,
            pagina
        }
    }).then(
        (res) => res.data
    );
    return response.data;
};
