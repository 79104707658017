

import { getConfigFile } from '../app-config';
import { getEnvironment } from '../environment';
import BaseAPI from './api';

const endpoint = 'entrenos';
const API = BaseAPI?.axiosInstance;
const env = getConfigFile().global;

const getTokenHeaders = () => {
    let unparsedToken = localStorage.getItem('user-token');
    if (!unparsedToken) {
        unparsedToken = ' ';
    }
    return {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(unparsedToken)}`

    }
}
export const getTrainingsForTheDay = async (
    search_term: string,
    pagina: string,
    satelite_id?: number,
    hora?: string
 ) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('user-token')
    };

    const parameters = {
        search_term,
        pagina,
        satelite_id,
        hora
      };
  
      
    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}`, {
        headers: getTokenHeaders(),
        params: parameters
    }).then(
        (res) => res.data
    );
    return response.data;
};


export const getTrainingsAssitantsForTheDay = async (
    search_term: string,
    pagina: string,
    satelite_id?: number,
    hora?: string
 ) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('user-token')
    };

    const parameters = {
        search_term,
        pagina,
        satelite_id,
        hora
      };
  
      
    const response = await API.get(`${getEnvironment().baseUrl}${endpoint}/asistentes`, {
        headers: getTokenHeaders(),
        params: parameters
    }).then(
        (res) => res.data
    );
    return response.data;
};
