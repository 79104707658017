import styled from 'styled-components';

export const DropDownHeader = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const options = ['10', '20'];

export const DropDownListContainer = styled('div')``;

export const DropDownList = styled('ul')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 0px;
  position: absolute;
  min-width: 120px;
  z-index: 1;
  left: 0;
  top: 45px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
`;

export const ListItem = styled('li')`
  list-style: none;
  font-size: 1rem;
  line-height: 22px;
  color: #262626;
  margin: 9px 10px;
  cursor: pointer;
  width: 100%;
`;
