import React from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router';
import WhatsappSvg from '../assets/whatsapp.svg';
import UserPhotoSvg from '../assets/userphoto.svg';
import LogoutSvg from '../assets/logout.svg';
import CompanySvg from '../assets/company.svg';
import HomeSvg from '../assets/home.svg';
import HelpSvg from '../assets/help.svg';

import { setTrainingPoint } from '../../app/trainingPointReducer';
import { goToMainPage } from '../Auth/Login';

function Header() {
    const dispatch = useAppDispatch();
    const trainingPoint = useAppSelector((state) => state?.trainingPoint?.trainingPoint)
    const company = useAppSelector((state) => state?.user?.userProfile?.empresa)
    const user = useAppSelector((state) => state?.user?.userProfile)
    const navigate = useNavigate()
    const loggedIn = useAppSelector((state) => state.user.isLoggedIn);

    const goToMain = async () => {
        goToMainPage(user, navigate);
    };

    const logout = () => {
        localStorage.removeItem('user-token');
        localStorage.removeItem('user-profile');
        localStorage.removeItem('training-point');
        sessionStorage.removeItem('loggedIn');
        dispatch(setTrainingPoint({}));
        navigate('/login');
    }
    return (

        <header className="flex w-full  items-center w-full bg-primary px-7 py-1.5">
            <div className='flex flex-start  w-1/4 justify-start'>
                <h1>Gym Connect</h1>
            </div>
            <div className='flex justify-center items-end w-3/4'>
                <div className='flex items-center w-full'>
                    {trainingPoint?.descripcion || company?.nombre ?
                        <>

                            <div className='flex w-1/2 justify-end items-center '>
                                {trainingPoint?.descripcion ? (
                                    <>
                                        <img className="max-h-8 mr-2" src={UserPhotoSvg} alt="trainingPointIcon" />
                                        <h3 className='whitespace-nowrap text-ellipsis'>
                                            {trainingPoint?.descripcion}
                                        </h3>
                                    </>

                                ) : ''
                                }
                                {company?.nombre ? (
                                    <>
                                        <img className="max-h-8 mr-2" src={CompanySvg} alt="companyIcon" />
                                        <h3 className='whitespace-nowrap text-ellipsis'>
                                            {company?.nombre}
                                        </h3>
                                    </>
                                ) : ''
                                }
                            </div>

                        </>

                        : ""}


                    <div className={'flex justify-end items-center cursor-pointer  ' + (trainingPoint.descripcion ? 'w-1/6' : 'w-1/2')} onClick={goToMain}>
                        <img className="max-h-8 mr-2" src={HomeSvg} alt="homeIcon" />
                        <h3>Inicio</h3>
                    </div>
                    {/*trainingPoint?.descripcion ?
                        <div className={'flex justify-end items-center cursor-pointer  ' + (trainingPoint.descripcion ? 'w-1/6' : 'w-1/2')} onClick={goToClasses}>
                            <h3>Clases</h3>
                        </div>
                    : ""*/}
                    {/*trainingPoint?.descripcion ?
                        <div className={'flex justify-end items-center cursor-pointer  ' + (trainingPoint.descripcion ? 'w-1/6' : 'w-1/2')} onClick={goToTrainings}>
                            <h3>Entrenos</h3>
                        </div>
                : ""*/}
                    <div className={'flex justify-end items-center cursor-pointer ' + (trainingPoint.descripcion ? 'w-1/6' : 'w-1/2')}>
                        <img className="max-h-8 mr-2" src={HelpSvg} alt="helpIcon" />
                        <h3>Ayuda</h3>
                    </div>
                    <div className={'flex justify-end items-center cursor-pointer ' + (trainingPoint.descripcion ? 'w-1/5' : 'w-1/2')}>
                        <img className="max-h-8 mr-1" src={WhatsappSvg} alt="wwhatsappIcon" />
                        <h3>Contáctanos</h3>
                    </div>
                    {/*loggedIn? 'logged':'Not logged'*/}
                    {loggedIn ? (
                        <div className={'flex justify-end items-center cursor-pointer ' + (trainingPoint.descripcion ? 'w-1/6' : 'w-1/2')} onClick={logout}>
                            <img className="max-h-8 mr-1" src={LogoutSvg} alt="logoutIcon" />
                            <h3>Salir</h3>
                        </div>
                    ) : ''}
                </div>
            </div >
        </header>
    );
}

export default Header;